import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { AdvisorCard, CollapsibleText, InvestorLayout, TipCard, formatDollars } from 'components';
import { useAccountsData, useAuth, useLeadsData } from 'contexts';
import Checklist from 'logos/advisor-tips/checklist.jpeg';
import Coins from 'logos/advisor-tips/coins.jpeg';
import Laptop from 'logos/advisor-tips/laptop.jpeg';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'block',
    fontWeight: 600,
    padding: '0rem',
    margin: '0rem 0 0 0',
    textAlign: 'center',
    maxWidth: '1280px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
      padding: '0.5rem'
    }
  },
  headerP: {
    display: 'block',
    textAlign: 'left',
    maxWidth: '720px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
    height: '100%',
    padding: '2rem',
    paddingBottom: '4rem',
    backgroundColor: '#EFF2F5',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  rowHeader: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    padding: '2rem',
    paddingTop: '1rem',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem'
    }
  },
  rowWhite: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '2rem',
    width: '100%',
    padding: '2rem',
    backgroundColor: 'white'
  },
  featuresContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '2rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: '0'
    }
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '2rem',
    width: '100%',
    padding: '0 4rem 2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  textRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
    padding: '4rem 6rem 1rem',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem'
    }
  },
  collapsibleTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  collapsibleTitleText: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

export const AdvisorMatch = () => {
  const classes = useStyles();
  const { currentUser, leadId } = useAuth();
  const [advisorProfiles, setAdvisorProfiles] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const { advisor_profile_id } = useParams<{ advisor_profile_id?: string }>();
  const userData = useAccountsData();
  const leadData = useLeadsData();

  const {
    investorUserData: { advisor, score_fees, stats_breakdown, lead }
  } = userData || leadData;

  const getAdvisorProfiles = useCallback(async () => {
    let response = undefined;
    if (currentUser) {
      const ID = await currentUser.getIdToken();
      response = await fetch(
        `${url}/api/v1/advisor_profile_locations.json?include=advisor,advisor_firm,advisor_profile_items`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ID}`
          }
        }
      );
    } else if (leadId) {
      response = await fetch(
        `${url}/api/v1/advisor_profile_locations.json?include=advisor,advisor_firm,advisor_profile_items`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    }
    if (response) {
      const json = await response.json();
      setAdvisorProfiles(json['data']);
    }
    setLoading(false);
  }, [currentUser, leadId]);

  const getAdvisorProfile = useCallback(
    async (id: string | undefined) => {
      const ID = await currentUser.getIdToken();
      let endpoint = `${url}/api/v1/advisor_profiles/${id}.json?include=advisor,advisor_firm,advisor_profile_items`;
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setAdvisorProfiles([json['data']]);
      setLoading(false);
    },
    [currentUser]
  );

  useEffect(() => {
    if (advisor_profile_id) {
      getAdvisorProfile(advisor_profile_id);
    } else {
      getAdvisorProfiles();
    }
  }, [getAdvisorProfile, getAdvisorProfiles, advisor_profile_id]);

  const advisorMatchesText = (() => {
    if (advisorProfiles.length === 0) {
      return 'We currently have no financial advisor matches for you. Please contact support.';
    }
    if (advisor?.is_broker) {
      return (
        <>
          Your current advisor is dually registered. The advisors you matched with below are fiduciaries,{' '}
          <i>all the time</i>. Schedule a free portfolio and fee review with your matches below.
        </>
      );
    }
    if (score_fees && score_fees < 80) {
      if (stats_breakdown.fee_potential && Math.floor(stats_breakdown.fee_potential / 500) * 500) {
        return `You could be avoiding more than ${formatDollars(
          Math.floor(stats_breakdown.fee_potential / 500) * 500
        )} in fees over the next five years.  Schedule a free portfolio and fee review with your matches below.`;
      } else
        return 'Your fees are higher than they need to be.  Schedule a free portfolio and fee review with your matches below.';
    }
    const firstName = currentUser?.displayName ? currentUser.displayName.split(' ')[0] : lead?.first_name;
    if (advisor) {
      return `${
        firstName ? firstName + ', schedule' : 'Schedule'
      } a free portfolio and fee review with your matches below.`;
    } else {
      return `${firstName ? firstName + ', schedule' : 'Schedule'} a free portfolio review with your matches below.`;
    }
  })();

  return (
    <InvestorLayout>
      {loading ? (
        <h1 className={classes.header}>Loading...</h1>
      ) : (
        <>
          <div className={classes.rowHeader}>
            <div>
              <h1 className={classes.header}>{advisorMatchesText}</h1>
            </div>
            <p className={classes.headerP}>
              Rainbook works exclusively with independent fiduciary RIAs. We curate and partner with premier wealth
              management firms that collectively manage over $22 billion in assets. Our network of CFP®, CFA, and
              CPA-designated advisors offers comprehensive financial planning with impeccable credentials.
            </p>
            <div className={classes.featuresContainer}>
              <div className={classes.collapsibleTitle}>
                <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
                <b>Fiduciary</b>
                <span className={classes.collapsibleTitleText}>
                  : Legally bound to act in your best interest. No brokers.
                </span>
              </div>
              <div className={classes.collapsibleTitle}>
                <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
                <b>Certified</b>
                <span className={classes.collapsibleTitleText}>: Designated CFP®, CFA or CPA.</span>
              </div>
              <div className={classes.collapsibleTitle}>
                <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
                <b>Fee-only</b>
                <span className={classes.collapsibleTitleText}>: Competitive pricing. No hidden fees.</span>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <AdvisorCard advisorProfile={advisorProfiles[0]} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
            <AdvisorCard advisorProfile={advisorProfiles[1]} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
          </div>
        </>
      )}
      <div className={classes.textRow}>
        <CollapsibleText
          title="About Rainbook's Solicitation Agreement"
          text={
            <>
              <p>
                As part of the services offered by Rainbook Advisors, LLC (“Rainbook”) through the Rainbook platform
                (the “Platform”), Rainbook offers users of the Platform (“User(s)”) the opportunity to match with
                certain third party registered investment advisors in the Rainbook network (“Advisor(s)”), based on each
                User’s profile and preferences. If requested, Rainbook will present Users with matched Advisors through
                the Platform, where they can review additional information about the matched Advisors and their
                respective services. Upon review, Rainbook will connect Users directly with Advisors where they can
                schedule a free consultation. Additional information about matched Advisors and their respective
                services, disclosures and fees may be found through the Advisor’s Form ADV, which can be accessed under
                each Advisor’s profile. If a User decides to formally engage an Advisor, they will enter into a written
                advisory agreement directly with the Advisor.
              </p>
              <p>
                Rainbook has entered into a written agreement with each Advisor on the Platform, which provides for a
                fee to be paid from Advisor to Rainbook if a User engages an Advisor. The fee earned by Rainbook will be
                up to 30% of any revenue generated by an engaged Advisor for the services offered by them to a User.
                This revenue sharing arrangement will continue for the entirety of a User’s “client-advisor”
                relationship. Rainbook receives this fee directly from the Advisor and no portion of this fee will be
                charged to a User by an Advisor or Rainbook.
              </p>
              <p>
                For the avoidance of doubt, you, the user, will not pay higher fees as a result of being referred by
                Rainbook.
              </p>
              <p>
                Rainbook does not provide investment advice on behalf of an Advisor and Rainbook does not act in any way
                to bind a User or an Advisor. Each User will enter into a written advisory agreement with an Advisor and
                no engagement of an Advisor will occur until such agreement is entered into between User and Advisor
                only.
              </p>
              <p>
                Should you have any questions about this disclosure, Please contact Rainbook with any questions about
                this disclosure, our services, the Platform or any of the Advisors in our network.
              </p>
            </>
          }
        />
        <CollapsibleText
          title="Additional Disclosure"
          text={
            <>
              <p>
                Rainbook Advisors, LLC a wholly owned subsidiary of Rainbook Holdings Inc., is an SEC registered
                investment advisor.
              </p>
              <p>
                Registration with the U.S. Securities and Exchange Commission as an investment advisor does not imply a
                certain level of skill or training. Rainbook Advisors does not participate in the management of any
                user’s account by an Advisor or provide advice regarding specific investments. Rainbook Advisors also
                does not establish advisory accounts or manage any client assets. The Advisors are responsible for
                determining and implementing specific investment strategies for the users. It is solely the
                responsibility of the user to determine whether to continue working with its Current Advisor and/or
                whether to meet with and/or retain any Referred Advisor with whom the user is matched with through the
                platform.
              </p>
              <p>For more details, see Rainbook's CRS and Rainbook's Form ADV Part 2.</p>
            </>
          }
        />
      </div>
      <div className={classes.rowWhite}>
        <h1>3 Tips to Prepare for Your Call:</h1>
        <div className={classes.cardContainer}>
          <TipCard img={Checklist} header="Define Your Goals" text="" link="" />
          <TipCard img={Coins} header="Communicate Expectations" text="" link="" />
          <TipCard img={Laptop} header="Understand Your Risk Tolerance" text="" link="" />
        </div>
      </div>
    </InvestorLayout>
  );
};
