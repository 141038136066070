import React, { useState } from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { FaExclamationTriangle, FaSearchDollar } from 'react-icons/fa';
import { IoIosStar } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { Dialog, DialogContent } from '@material-ui/core';
import { Widget } from '@typeform/embed-react';

import { AdvisorMatchCTA, AdvisorNamePopup, InvestorLayout } from 'components';
import { NewAccountSteps, ProcessingMessage } from 'components';
import SignUpIframe from 'components/auth/SignUpIframe';
import { Step } from 'components/cards/NewAccountSteps';
import DialogTitleX from 'components/dialogs/DialogTitleX';
import { useAuth, useLeadsData } from 'contexts';

import { advisorText } from './InsightsText';
import useStyles from './LandingStyles';
import { AdvisorRedFlagsSection, FeesSection } from './sections';

export const LandingNoAuthAdvisor = () => {
  const classes = useStyles();
  const [openSignup, setOpenSignup] = useState(false);
  const [openedTypeform, setOpenedTypeform] = useState(true);
  const { leadId } = useAuth();
  const {
    investorUserData: { lead, advisor, advisor_searched_at, score_compliance, provided_account_size_max },
    scoreSubmitted,
    setScoreSubmitted
  } = useLeadsData();
  const [advisorNameDialogOpen, setAdvisorNameDialogOpen] = useState(false);
  const onAdvisorNameDialogClose = () => {
    setAdvisorNameDialogOpen(false);
  };
  const openAdvisorNameDialog = () => {
    setAdvisorNameDialogOpen(true);
  };

  const credentialInsights = () => {
    let insights: string[] = [];
    if (!advisor) {
      return [];
    }
    if (advisor.is_ia && advisor.is_broker) {
      insights.push(advisorText.dually_registered(advisor.first_name));
    }
    if (!advisor.is_ia) {
      if (advisor.is_broker) {
        insights.push(advisorText.broker_only);
      } else {
        insights.push(advisorText.not_registered);
      }
    }
    if (advisor.num_disclosures > 0) {
      insights.push(advisorText.has_disclosures(advisor.first_name, advisor.last_name));
    }
    if (advisor.is_cfp === false && advisor.is_cfa === false && advisor.is_cpa === false) {
      insights.push(advisorText.no_credentials(advisor.first_name));
    }
    if (insights.length > 0) return insights;
    return ['No apparent issues to report.', 'We will continue to monitor.'];
  };

  let advisorSteps: [Step] = [
    {
      title: '',
      isComplete: score_compliance,
      actionText: 'Analyze my Advisor - Start Now',
      action: 'Pwvyj9YC',
      score_attribute: 'advisor_searched_at'
    }
  ];

  let advisorErrorMessage: string | undefined | JSX.Element = undefined;
  if (!advisor) {
    if (advisor_searched_at) {
      advisorSteps = [
        {
          title: 'Advisor not found',
          isComplete: !!advisor,
          actionText: 'Try Again',
          action: openAdvisorNameDialog,
          score_attribute: 'advisor_searched_at'
        }
      ];
    } else if (scoreSubmitted.includes('advisor_searched_at')) {
      advisorErrorMessage = (
        <div className={classes.row}>
          <ProcessingMessage />
        </div>
      );
    }
  } else if (!score_compliance) {
    advisorErrorMessage = 'Calculating... check back soon.';
  }

  const closeSignup = () => {
    setOpenSignup(false);
  };
  const closeTypeform = () => {
    setOpenedTypeform(false);
  };
  const handleTypeformSubmit = () => {
    setScoreSubmitted((arr: any) => [...arr, 'advisor_searched_at']);
  };

  const typeformDialog = (
    <Dialog fullScreen open={openedTypeform} fullWidth={true} maxWidth={'lg'} onClose={closeTypeform}>
      <DialogTitleX onClose={closeTypeform} style={{ backgroundColor: 'rgb(0, 65, 114)' }} />
      <DialogContent style={{ backgroundColor: 'rgb(0, 65, 114)' }}>
        <Widget
          id="Pwvyj9YC"
          onSubmit={handleTypeformSubmit}
          style={{ width: '100%', height: '100%' }}
          hidden={{
            lead_client_id: leadId,
            advisor_name: advisor?.name || lead?.advisor_name || '',
            email: lead?.email || ''
          }}
        />
      </DialogContent>
    </Dialog>
  );

  return (
    <InvestorLayout>
      {provided_account_size_max ? (
        <div className={classes.pageHeader}>
          <h2>{lead?.first_name}, you are now matched with two fiduciary advisors!</h2>
          <Link className={classes.button} to="/a/advisor-upgrade/match">
            Meet your matched advisors now
          </Link>
        </div>
      ) : (
        <h2 className={classes.pageHeader}>
          Discover Your Potential Savings and Upgrade Your Advisor Relationship Today. Get Started Below for Your Fees
          and Advisor Analyzer Report.
        </h2>
      )}
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            <FaExclamationTriangle style={{ verticalAlign: 'middle' }} />{' '}
            {advisor
              ? `Red flag analysis of your advisor ${advisor.first_name}:`
              : 'Does your advisor have any red flags?'}
          </h2>
        </div>
        {!advisor && <AdvisorRedFlagsSection />}
        {advisorErrorMessage ? (
          advisorErrorMessage
        ) : advisor ? (
          <div className={score_compliance < 90 ? classes.rowAlert : classes.rowRow}>
            {score_compliance < 90 ? (
              <AiFillExclamationCircle color="#EEE" size={50} />
            ) : (
              <IoIosStar color="rgb(21, 120, 246)" size={38} />
            )}
            <ul className={classes.standardList}>
              {credentialInsights().map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={classes.row}>
            {!advisor_searched_at && typeformDialog}
            <NewAccountSteps steps={advisorSteps} />
            <AdvisorNamePopup open={advisorNameDialogOpen} onClose={onAdvisorNameDialogClose} />
          </div>
        )}
        {score_compliance && <AdvisorMatchCTA linkTo="/a/advisor-upgrade/match" />}
      </div>
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            <FaSearchDollar /> Are you paying too much in fees?
          </h2>
        </div>
        <FeesSection />
        <div className={classes.row}>
          <div className={classes.match}>
            <div className={classes.button} onClick={() => setOpenSignup(true)}>
              Securely Register to Analyze Fees
            </div>
          </div>
          <Dialog open={openSignup} onClose={closeSignup} aria-labelledby="form-dialog-title">
            <SignUpIframe />
          </Dialog>
        </div>
      </div>
    </InvestorLayout>
  );
};
