import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { Widget } from '@typeform/embed-react';

import { AAPhoneDialog } from 'components/dialogs';
import { useAccountsData, useAuth } from 'contexts';
import aaLogo from 'logos/AdvisorAnalyzerLogoBlue.png';
import rbLogo from 'logos/RainbookLogo300Black.png';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))',
    color: 'white',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5%'
    }
  },
  rbLogo: {
    width: '10rem',
    position: 'absolute',
    top: '-1.5rem',
    left: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      width: '6rem',
      top: '-0.5rem'
    },
    filter: 'brightness(0) invert(1);',
    transition: 'opacity 0.25s ease'
  },
  aaLogo: {
    width: '12rem',
    position: 'absolute',
    top: '1.8rem',
    left: '1.8rem',
    [theme.breakpoints.down('sm')]: {
      width: '6rem'
    },
    filter: 'brightness(0) invert(1);',
    transition: 'opacity 0.25s ease',
    zIndex: -1
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      padding: '0.5rem',
      paddingTop: '4rem'
    },
    width: '100%'
  },
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  welcomeContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    padding: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    color: '#333',
    [theme.breakpoints.down('xs')]: {
      gap: '1rem'
    }
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    color: '#333',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  typeformContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  congratsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
    borderRadius: 8,
    color: 'white'
  },
  btn: {
    ...btnStyles,
    width: 'auto',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.5s ease'
    }
  },
  smallBtn: { width: '100%', textAlign: 'center', cursor: 'pointer', color: '#333', marginTop: '1.5rem' },
  continueText: {
    textAlign: 'center',
    maxWidth: 500
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    color: '#333',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.5rem'
    }
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '5rem',
    height: '5rem',
    [theme.breakpoints.down('xs')]: {
      width: '4rem',
      height: '4rem',
      fontSize: '0.8rem'
    },
    borderRadius: '0.5rem',
    padding: '0.5rem',
    justifyContent: 'center',
    backgroundColor: 'white',
    fontWeight: 700
  },
  stepDash: {
    width: '2rem',
    height: 2,
    backgroundColor: '#333'
  }
}));

const btnStyles = {
  color: '#ffffff',
  backgroundColor: 'rgb(21, 120, 246)',
  borderRadius: '9px',
  width: 200,
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: 'none',
  fontSize: '12pt',
  padding: 12,
  marginBottom: 20
};

export const InvestorStart = () => {
  const classes = useStyles();

  const { currentUser, noAdvisor, setNoAdvisor, updateUserAttributes } = useAuth();
  const {
    onboarding,
    setOnboarding,
    setDoneOnboarding,
    setUsingBaa,
    baaUserData,
    investorUserData,
    BaaAccounts,
    setScoreSubmitted
  } = useAccountsData();

  const doneConnecting =
    baaUserData &&
    investorUserData &&
    (baaUserData.in_progress_at ||
      investorUserData.statement_in_progress ||
      baaUserData.baa_investor_credentials?.length ||
      BaaAccounts.length);
  const doneTypeform = investorUserData?.provided_location_preference;
  const has2FA = useMemo(() => currentUser?.multiFactor?.enrolledFactors?.length > 0, [currentUser]);
  const skip2FA = useMemo(() => !currentUser?.emailVerified, [currentUser]);
  const donePhone = has2FA || skip2FA;

  const [step, setStep] = useState(donePhone ? (doneTypeform ? 3 : 1) : 0);

  const takeStep = () => {
    setStep((step) => step + 1);
  };

  const clickNoAdvisor = () => {
    setNoAdvisor(true);
    updateUserAttributes({ provided_no_advisor: true });
    takeStep();
  };
  const clickAdvisor = () => {
    setNoAdvisor(false);
    updateUserAttributes({ provided_no_advisor: false });
    takeStep();
  };

  const handlePhoneClose = (zip: string, dob: string) => {
    if (zip || dob) {
      updateUserAttributes({ zip: zip, dob: dob });
    }
    takeStep();
  };

  useEffect(() => {
    if (onboarding) {
      ReactGA.event('tutorial_begin');
    }
  }, [onboarding]);

  const welcomePane = (
    <div className={classes.welcomeContainer}>
      <h2>Welcome to Rainbook!</h2>
      <div className={classes.welcomeContent}>
        <img src={aaLogo} alt="advisor-analyzer" style={{ width: '200px' }} />
        <h3>How can we best help you?</h3>
        <div className={classes.optionContainer}>
          <button className={classes.btn} onClick={clickAdvisor}>
            Analyze my fees and advisor
          </button>
          <button className={classes.btn} onClick={clickNoAdvisor} style={{ backgroundColor: '#888' }}>
            Get matched with a fiduciary advisor
          </button>
        </div>
      </div>
    </div>
  );

  const noAdvisorTypeformPane = useMemo(() => {
    const handleNoAdvisorTypeformSubmit = () => {
      setScoreSubmitted((arr: any) => [...arr, 'provided_location_preference']);
      setStep((step) => step + 1);
    };
    return (
      <div className={classes.typeformContainer}>
        <div style={{ height: '65vh', width: '80vw', marginBottom: '1rem' }}>
          <Widget
            id="KVKEduIx"
            style={{ width: '100%', height: '100%' }}
            hidden={{ user_id: currentUser.uid, email: currentUser.email }}
            onSubmit={handleNoAdvisorTypeformSubmit}
          />
        </div>
      </div>
    );
  }, []);

  const panes = [<AAPhoneDialog closeCallback={handlePhoneClose} btnStyles={btnStyles} />, welcomePane];
  if (noAdvisor) {
    panes.push(noAdvisorTypeformPane);
  }

  useEffect(() => {
    if ((noAdvisor !== null && ((noAdvisor && step >= 3) || (!noAdvisor && step >= 2))) || (doneTypeform && step > 0)) {
      ReactGA.event('tutorial_complete');
      setOnboarding(false); //triggers <Redirect
      setDoneOnboarding(true);
      setUsingBaa(true);
    }
  }, [step, noAdvisor]);

  const Step = ({ num, display }: { num: number; display?: number }) => {
    return (
      <div className={classes.step} style={{ color: step === num ? 'rgb(21, 120, 246)' : '#333' }}>
        {step > num ? (
          <AiFillCheckCircle style={{ color: 'rgb(21, 120, 246)' }} size={40} />
        ) : (
          <>
            Step <span style={{ fontSize: 35 }}>{display || num}</span>
          </>
        )}
      </div>
    );
  };

  const Steps = () => {
    return (
      <div className={classes.stepContainer}>
        <Step num={0} display={1} />
        <div className={classes.stepDash} />
        <Step num={2} />
      </div>
    );
  };

  return onboarding ? (
    <div className={classes.root}>
      <img src={rbLogo} alt="logo" className={classes.rbLogo} style={{ opacity: step === 0 ? 100 : 0 }} />
      <img src={aaLogo} alt="logo" className={classes.aaLogo} style={{ opacity: step > 0 ? 100 : 0 }} />
      <div className={classes.container}>
        {step < 4 && <Steps />}
        {panes[step]}
      </div>
    </div>
  ) : (
    <Redirect to="/" />
  );
};
