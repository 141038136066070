import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import PhoneInput from 'react-phone-number-input/input';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography
} from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';
import firebase from 'firebase/app';
import moment from 'moment';

import { useAuth, useAccountsData } from 'contexts';
import SecureAuthImg from 'logos/SecureAuth.png';

import DialogTitleX from './DialogTitleX';

interface AAPhoneDialogProps {
  closeCallback: (zip: string, dob: string) => void;
  btnStyles?: any;
}

export const AAPhoneDialog = ({ closeCallback, btnStyles }: AAPhoneDialogProps) => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const { currentUser, updateUserPhone, getRbUser } = useAuth();

  const [verificationId, setVerificationId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [verificationFailed, setVerificationFailed] = useState('');
  const [zip, setZip] = React.useState('');
  const [dob, setDob] = React.useState('');
  const recaptchaRef = useRef<any>(null);

  const resetRecaptcha = () => {
    (window as any).recaptchaVerifier.clear();
    if (recaptchaRef.current) {
      recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
    }
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    });
  };

  const has2FA = useMemo(() => currentUser?.multiFactor?.enrolledFactors?.length > 0, [currentUser]);
  const skip2FA = useMemo(() => !currentUser?.emailVerified, [currentUser]);

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZip(event.target.value);
  };

  const handleDobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDob(event.target.value);
  };

  const handlePhoneChange = (value: string) => {
    setPhoneNumber('+' + value.replace(/\D/g, ''));
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(event.target.value);
  };

  const reAuth = () => {
    currentUser
      .reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(currentUser.email, password))
      .then(() => {
        setPassword('');
        setVerifyPassword(false);
        handleSubmit2FA();
      })
      .catch((e: any) => {
        setPasswordError(e.message);
      });
  };

  const handleClose = useCallback(() => {
    setVerificationComplete(false);
    setVerificationFailed('');
    setVerificationCode('');
    setVerificationId('');
    closeCallback(zip, dob);
  }, [closeCallback, dob, zip]);

  useEffect(() => {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      'expired-callback': () => {
        console.error('expired captcha');
      }
    });
  }, []);

  const handleSubmit2FA = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (has2FA || skip2FA) return handleClose();

    if (!verificationId) {
      currentUser.multiFactor
        .getSession()
        .then(function (multiFactorSession: any) {
          // Specify the phone number and pass the MFA session.
          var phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession
          };
          var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // Initialize new reCaptcha verifier
          resetRecaptcha();
          // Send SMS verification code.
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, (window as any).recaptchaVerifier);
        })
        .then(function (vId: React.SetStateAction<string>) {
          setVerificationId(vId);
          setVerificationFailed('');
          setPasswordError('');
        })
        .catch((e: any) => {
          console.error(e.message);
          if (e.code === 'auth/argument-error') {
            setVerificationFailed('Invalid Phone Number');
          } else if (e.code === 'auth/operation-not-allowed') {
            setVerificationFailed('Unsupported Phone Number');
          } else if (e.code === 'auth/requires-recent-login') {
            setVerifyPassword(true);
          } else if (e.code === 'auth/second-factor-already-in-use') {
            updateUserPhone(phoneNumber);
            setVerificationComplete(true);
          } else if (e.code === 'auth/unverified-email') {
            setVerificationFailed('Need to verify email first.');
          } else {
            setVerificationFailed('...');
          }
        });
    } else {
      var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
      var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      currentUser.multiFactor
        .enroll(multiFactorAssertion, 'Phone')
        .then(() => {
          updateUserPhone(phoneNumber);
          setVerificationComplete(true);
        })
        .catch((e: any) => {
          console.error(e.message);
          if (e.code === 'auth/invalid-verification-code') {
            setVerificationFailed('Invalid SMS Code');
          } else {
            setVerificationFailed(e.message);
          }
        });
    }
  };

  const setZipDob = useCallback(async () => {
    const data = await getRbUser(currentUser);
    setZip(data['zip'] || data?.lead?.zip || '');
    setDob(data['dob'] || data?.lead?.dob || '');
    let phone = data?.lead?.phone_number;
    if(phone){
      phone = '+1'+phone.replace('+1', '').replace(/\D/g, '');
      setPhoneNumber(phone);
    }
  }, [currentUser, getRbUser]);

  useEffect(() => {
    setZipDob().catch(console.error);
  }, [setZipDob]);

  useEffect(() => {
    if (verificationId && verificationComplete) handleClose();
  }, [verificationId, verificationComplete, handleClose]);

  const secureImg = <img width={isXS ? '200' : '300'} src={SecureAuthImg} alt="secure-auth-img" />;

  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          width: isXS ? '100%' : '65%',
          backgroundColor: 'white',
          padding: '2rem',
          marginTop: isXS ? '2rem' : '0',
          borderRadius: 16,
          color: '#333',
          zIndex: 50
        }}
      >
        <Grid item sm={6} xs={12}>
          {!verificationId && (
            <>
              <div>
                <Typography
                  style={{
                    marginBottom: 24,
                    fontSize: 20,
                    fontWeight: 600
                  }}
                >
                  Enable Two-Factor Authentication
                </Typography>
                <Grid
                  container
                  style={{
                    marginBottom: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20
                  }}
                >
                  <div>
                    <InputLabel
                      shrink
                      id="dob_date_label"
                      htmlFor="dob_date"
                      style={{ width: '80%', display: 'inline-flex' }}
                    >
                      Date of Birth
                    </InputLabel>
                    <Input
                      id="dob_date"
                      type="date"
                      aria-labelledby="dob_date_label"
                      placeholder="Date of Birth"
                      inputProps={{ min: '1920-01-01', max: moment().subtract(15, 'years').format('YYYY-MM-DD') }}
                      value={dob}
                      onChange={handleDobChange}
                      style={{
                        padding: 10,
                        width: '80%',
                        height: 40
                      }}
                    />
                  </div>
                  <div>
                    <InputLabel shrink id="zip_label" htmlFor="zip" style={{ width: '80%', display: 'inline-flex' }}>
                      US Zip Code (5-digit)
                    </InputLabel>
                    <Input
                      id="zip"
                      type="text"
                      inputMode="numeric"
                      inputProps={{ pattern: '\\d*', maxLength: 5 }}
                      aria-labelledby="zip_label"
                      placeholder="55555"
                      value={zip}
                      onChange={handleZipChange}
                      style={{
                        padding: 10,
                        width: '80%'
                      }}
                    />
                  </div>
                </Grid>
              </div>
              {verificationFailed && <p style={{ color: 'red', fontSize: 14 }}>{verificationFailed}</p>}
              {has2FA && (
                <Typography>
                  Phone Number Added <AiFillCheckCircle size={16} color="#1FC277" />
                </Typography>
              )}
              <Grid
                container
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 20
                }}
              >
                <form onSubmit={!verificationComplete ? handleSubmit2FA : undefined}>
                  <div>
                    {!has2FA && !skip2FA && (
                      <>
                        <InputLabel
                          shrink
                          id="phone_label"
                          htmlFor="phone"
                          style={{ width: '80%', display: 'inline-flex' }}
                        >
                          US Phone Number for SMS code
                        </InputLabel>
                        <div
                          className="MuiInputBase-root MuiInput-root MuiInput-underline"
                          style={{
                            padding: 10,
                            width: '80%',
                            height: 40
                          }}
                        >
                          <PhoneInput
                            id="phone_label"
                            className="MuiInputBase-input MuiInput-input"
                            country="US"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                          />
                        </div>
                        <div>
                          <p style={{ fontSize: 'smaller' }}>
                            Information is used for security verification and to deliver our services to you. We adhere
                            to a strict no-spam policy.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <Grid item>
                    {!verificationComplete && (
                      <button style={btnStyles} type="submit">
                        <div>Continue</div>
                      </button>
                    )}
                  </Grid>
                </form>
              </Grid>
            </>
          )}
          {verificationId && !verificationComplete && (
            <form onSubmit={handleSubmit2FA}>
              <Typography variant="h6" style={{ marginBlock: '1.5em' }}>
                Enter Your Verification Code
              </Typography>
              <p
                style={{
                  marginBlock: '1.5em',
                  color: '#6c757d'
                }}
              >
                We've sent a message to {phoneNumber.replace('+1', '').replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}{' '}
                {<br />} Please enter the code you received.
              </p>
              <Input
                name="otp"
                inputMode="numeric"
                type="text"
                autoFocus
                inputProps={{ pattern: '\\d*', maxLength: 6 }}
                placeholder="123456"
                onChange={handleCodeChange}
                style={{
                  width: '135px',
                  fontFamily: 'monospace',
                  MozAppearance: 'textfield',
                  borderRadius: '6px',
                  border: '1px solid',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
                  margin: '4px',
                  paddingLeft: '8px',
                  paddingRight: 0,
                  height: '42px',
                  fontSize: '32px',
                  boxSizing: 'border-box'
                }}
              />
              {verificationFailed && <p style={{ color: 'red', fontSize: 14 }}>{verificationFailed}</p>}
              <DialogActions className={'phone-dialog-verify'}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    color: '#fff',
                    backgroundColor: 'rgb(23, 120, 250)'
                  }}
                >
                  Verify
                </Button>
              </DialogActions>
            </form>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          {secureImg}
        </Grid>
      </Grid>
      <Dialog open={verifyPassword} fullWidth maxWidth="xs">
        <DialogTitleX
          onClose={() => {
            setVerifyPassword(false);
          }}
        >
          Confirm your password.
        </DialogTitleX>
        <DialogContent>
          <p style={{ color: 'red' }}>{passwordError}</p>
          <TextField label="Password" type="password" onChange={(event) => setPassword(event.target.value)} />
          <DialogActions>
            <Button onClick={() => reAuth()}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <div ref={recaptchaRef}>
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
};
