import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { BsCalendar2Check, BsCheck } from 'react-icons/bs';

import { makeStyles } from '@material-ui/core';

import { formatAge } from 'components';
import { useAccountsData, useAuth, useLeadsData } from 'contexts';
import cfaImg from 'logos/credential-platforms/cfa.jpeg';
import cfpImg from 'logos/credential-platforms/cfp.png';
import cpaImg from 'logos/credential-platforms/cpa.jpg';
import noUserImg from 'logos/no-user-image.png';

interface InsightCardProps {
  advisorProfile: any;
  setIsExpanded: Function;
  isExpanded: boolean;
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.5rem',
    width: '50%',
    maxWidth: '35rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem rgba(0,0,0,0.25)',
    textDecoration: 'none',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    height: '100%',
    padding: '2rem 2rem 0'
  },
  topInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem 0',
    borderBottom: '1px solid #808080',
    [theme.breakpoints.down('md')]: {
      paddingTop: '3rem',
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  topRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '1rem',
    width: '100%',
    height: '100%',
    padding: '0 1rem',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      gap: '0.5rem'
    }
  },
  advisor: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '0.1rem 0.5rem 0.1rem 0.8rem',
    borderRadius: '0.5rem',
    backgroundColor: '#1DC577',
    fontWeight: 600,
    color: 'white',
    '&.schedulable': {
      cursor: 'pointer'
    }
  },
  name: {
    margin: '0',
    padding: '0',
    fontSize: '2rem'
  },
  company: {
    fontWeight: 600,
    color: 'rgb(21, 120, 246)',
    fontSize: '0.8rem'
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10rem',
    height: '10rem',
    borderRadius: '50%',
    border: '4px solid rgb(21, 120, 246)',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    '& img': {
      width: '95%',
      height: '95%',
      borderRadius: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '8rem',
      height: '8rem'
    }
  },
  infoDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '90%',
    marginTop: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem'
    }
  },
  basicInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '0.5rem',
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      gap: '0.25rem',
      textAlign: 'center'
    }
  },
  infoRow: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
    gap: '0.2rem',
    width: '100%',
    color: '#808080',
    fontWeight: 500,
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center'
    }
  },
  credentials: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '0.5rem',
    marginTop: '0.5rem'
  },
  credentialsImg: {
    width: '5rem',
    '& img': {
      width: '5rem'
    }
  },
  firmImg: {
    width: '100%',
    textAlign: 'center',
    '& img': {
      width: '350px',
      height: '100px',
      objectFit: 'contain'
    }
  },
  bottomInfo: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      left: -32,
      width: 'calc(100% + 64px)',
      flexDirection: 'column',
      zIndex: 1,
      borderRadius: '0 0 0.5rem 0.5rem',
      overflow: 'hidden'
    }
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    height: '3rem',
    cursor: 'pointer',
    color: '#808080',
    fontWeight: 500,
    borderBottom: '3px solid white',
    transition: 'all 0.25s ease',
    userSelect: 'none',
    [theme.breakpoints.down('md')]: {
      display: ({ showAll }: { showAll: boolean }) => (showAll ? 'flex' : 'none'),
      width: 'calc(100% - 4rem)',
      borderBottom: '1px solid #808080',
      '&::after': {
        content: '""',
        borderStyle: 'solid',
        borderWidth: '8px 0 8px 8px',
        borderColor: 'transparent transparent transparent rgb(21, 120, 246)',
        display: 'inline-block',
        position: 'absolute',
        top: '25px',
        right: '10%',
        transform: ({ showAll }: { showAll: boolean }) =>
          showAll ? 'translateY(-50%) rotate(270deg)' : 'translateY(-50%) rotate(90deg)'
      }
    }
  },
  selectedTab: {
    top: 0,
    display: 'flex !important',
    borderColor: 'rgb(21, 120, 246)',
    color: 'rgb(21, 120, 246)'
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
    minHeight: '360px',
    padding: '0 0 6rem',
    maxHeight: '380px',
    overflow: 'hidden',
    cursor: 'pointer',
    '&.expanded': {
      maxHeight: 'none',
      overflow: 'auto',
      cursor: 'auto'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '3rem',
      marginBottom: '4rem',
      minHeight: 'unset'
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'hidden',
    '& div > p': {
      marginTop: 0
    }
  },
  showMoreLessClickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  degree: {
    marginTop: '0.5rem',
    color: '#808080',
    fontWeight: 500,
    fontSize: '0.9rem'
  },
  expertiseContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    width: '100%'
  },
  expertiseTag: {
    display: 'inline-block',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: '#EFF2F5',
    color: '#808080',
    fontWeight: 500,
    fontSize: '0.9rem'
  },
  firmDescription: {
    width: '100%',
    paddingBottom: '1em',
    paddingTop: '1em',
    borderBottom: 'solid #eee'
  },
  firmDocs: {
    width: '100%'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    padding: '1rem 0',
    borderTop: '1px solid #808080',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  schedule: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
    color: 'black',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  footerBtn: {
    width: '50%',
    height: '3rem',
    borderRadius: '0.5rem',
    border: 'none',
    backgroundColor: 'rgb(21, 120, 246)',
    color: 'white',
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'all 0.25s ease',
    '&:hover': {
      backgroundColor: 'rgb(21, 120, 246, 0.8)'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const credentialsToText: { [key: string]: string } = {
  is_cfp: 'Certified Financial Planner ™',
  is_cfa: 'Chartered Financial Analyst ®',
  is_cpa: 'Certified Public Accountant'
};

const credentialsToImg: { [key: string]: string } = {
  is_cfp: cfpImg,
  is_cfa: cfaImg,
  is_cpa: cpaImg
};

export const AdvisorCard = ({ advisorProfile, setIsExpanded, isExpanded }: InsightCardProps) => {
  const [tab, setTab] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const classes = useStyles({ showAll });
  const { currentUser } = useAuth();
  const userData = useAccountsData();
  const leadData = useLeadsData();
  const { createMeeting, investorUserData } = userData || leadData;

  const ref = useRef<HTMLDivElement>(null);

  const handleTabClick = (tab: number) => {
    setTab(tab);
    setShowAll((prev) => !prev);
  };

  const handleResize = () => {
    setShowAll(false);
  };

  const clickExpand = () => {
    setIsExpanded(true);
  };

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (showCalendar) {
        createMeeting({ advisor_profile_id: advisorProfile.advisor_profile_id });
      }
    }
  });

  useLayoutEffect(() => {
    if (ref && ref.current) {
      const { current } = ref;
      if (current && current.scrollHeight === 380) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    }
  }, [ref, tab]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!advisorProfile) {
    return <></>;
  }

  const credentials = ['is_cfp', 'is_cfa', 'is_cpa'].filter(
    (key) => advisorProfile.advisor && advisorProfile.advisor[key]
  );

  /*
   * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
   * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
   */
  const popupRootElement = document.getElementById('root');
  const calendlyReady = popupRootElement && advisorProfile.calendly_url;
  //advisorProfile is an AdvisorProfileLocation
  const meetingScheduled =
    investorUserData && investorUserData.meeting_advisor_profile_ids.indexOf(advisorProfile.advisor_profile_id) > -1;

  return (
    <div className={classes.card}>
      <div className={classes.mainContent}>
        <div className={classes.topInfo}>
          <div
            className={classes.imgContainer}
            style={{ backgroundImage: `url('${advisorProfile.image_url || noUserImg}')` }}
          />
          <div className={classes.topRight}>
            {calendlyReady ? (
              <div className={classes.advisor + ' schedulable'} onClick={() => setShowCalendar(true)}>
                <span>Rainbook Network Advisor</span>
                <BsCalendar2Check size={'1em'} />
              </div>
            ) : (
              <div className={classes.advisor}>
                <span>Rainbook Network Advisor</span>
                <BsCheck size={'20px'} />
              </div>
            )}
            <div className={classes.infoDetails}>
              <div className={classes.basicInfo}>
                <h3 className={classes.name}>
                  {advisorProfile.advisor?.first_name} {advisorProfile.advisor?.last_name}
                  {credentials.length > 0 ? ', ' : ''}
                  {credentials.map((key) => (
                    <span key={key}>
                      {key.substring(3).toUpperCase()}
                      <sup style={{ fontSize: 'small' }}>®</sup>{' '}
                    </span>
                  ))}
                </h3>
                <div className={classes.company}>{advisorProfile.advisor_firm?.name}</div>
              </div>
              <div className={classes.infoRow}>
                <div>
                  {advisorProfile.city}, {advisorProfile.state}
                  <br />
                  or meets remotely.
                </div>
                <div>{`${formatAge(advisorProfile.advisor?.experience_start_date, true)}`} experience</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.bottomInfo}>
          <div className={classes.tabs}>
            <div className={`${classes.tab} ${tab === 0 && classes.selectedTab}`} onClick={() => handleTabClick(0)}>
              Bio
            </div>
            <div className={`${classes.tab} ${tab === 1 && classes.selectedTab}`} onClick={() => handleTabClick(1)}>
              Credentials
            </div>
            <div className={`${classes.tab} ${tab === 2 && classes.selectedTab}`} onClick={() => handleTabClick(2)}>
              Education
            </div>
            <div className={`${classes.tab} ${tab === 3 && classes.selectedTab}`} onClick={() => handleTabClick(3)}>
              Expertise
            </div>
            <div className={`${classes.tab} ${tab === 4 && classes.selectedTab}`} onClick={() => handleTabClick(4)}>
              About Firm
            </div>
          </div>
          <div className={classes.tabContent + (isExpanded ? ' expanded' : '')} onClick={clickExpand} ref={ref}>
            {tab === 0 && (
              <div className={classes.contentContainer}>
                <h2>Bio</h2>
                <div dangerouslySetInnerHTML={{ __html: advisorProfile.bio || '' }} />
              </div>
            )}
            {tab === 1 && (
              <div className={classes.contentContainer}>
                <h2>Credentials</h2>
                <div dangerouslySetInnerHTML={{ __html: advisorProfile.credentials || '' }} />
                <div>
                  {credentials.map((key) => (
                    <div key={key} className={classes.credentials}>
                      <div className={classes.credentialsImg}>
                        <img src={credentialsToImg[key]} alt={credentialsToText[key]} />
                      </div>
                      <div>{credentialsToText[key]}</div>
                    </div>
                  ))}
                  {advisorProfile.advisor_profile_items.map((item: any) => (
                    <div key={item.id} className={classes.credentials}>
                      <div className={classes.credentialsImg}>
                        {!!item.logo_url && <img src={item.logo_url} alt={item.title} />}
                      </div>
                      <div>{item.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {tab === 2 && (
              <div className={classes.contentContainer}>
                <h2>Education</h2>
                <div dangerouslySetInnerHTML={{ __html: advisorProfile.education || '' }} />
              </div>
            )}
            {tab === 3 && (
              <div className={classes.contentContainer}>
                <h2>Expertise</h2>
                <div className={classes.expertiseContainer}>
                  {advisorProfile.expertise &&
                    advisorProfile.expertise.split(',').map((area: string) => (
                      <div key={area} className={classes.expertiseTag}>
                        {area}
                      </div>
                    ))}
                </div>
              </div>
            )}
            {tab === 4 && (
              <div className={classes.contentContainer}>
                <h2>{advisorProfile.advisor_firm?.name || 'About Firm'}</h2>
                {!!advisorProfile.advisor_firm && (
                  <>
                    {!!advisorProfile.advisor_firm.logo_url && (
                      <div className={classes.firmImg}>
                        <img src={advisorProfile.advisor_firm.logo_url} alt={advisorProfile.advisor_firm.name} />
                      </div>
                    )}
                    {!!advisorProfile.advisor_firm.description && (
                      <div
                        className={classes.firmDescription}
                        dangerouslySetInnerHTML={{ __html: advisorProfile.advisor_firm.description || '' }}
                      />
                    )}
                    <ul className={classes.firmDocs}>
                      {!!advisorProfile.advisor_firm.adv_url && (
                        <li>
                          <a href={advisorProfile.advisor_firm.adv_url} target="_blank" rel="noreferrer">
                            Form ADV
                          </a>
                        </li>
                      )}
                      {!!advisorProfile.advisor_firm.crs_url && (
                        <li>
                          <a href={advisorProfile.advisor_firm.crs_url} target="_blank" rel="noreferrer">
                            Form CRS
                          </a>
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>
            )}
            {showMore && !isExpanded && <div className={classes.showMoreLessClickable}>Show More...</div>}
          </div>
          <div className={classes.footer}>
            <div className={classes.schedule}>
              {meetingScheduled
                ? 'You have a meeting scheduled'
                : `Schedule an introductory call with ${advisorProfile.advisor?.first_name}`}
            </div>
            {calendlyReady && !meetingScheduled && (
              <>
                <button className={classes.footerBtn} onClick={() => setShowCalendar(true)}>
                  Schedule Now
                </button>
                <PopupModal
                  url={advisorProfile.calendly_url}
                  pageSettings={{ hideGdprBanner: true }}
                  prefill={{ name: currentUser?.displayName || '', email: currentUser?.email || '' }}
                  onModalClose={() => setShowCalendar(false)}
                  open={showCalendar}
                  rootElement={popupRootElement}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
