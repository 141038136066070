import React, { useMemo } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core';

import { LinksDropdown, NavDropdown, NoAuthNavDropdown } from 'components';
import { useAccountsData, useAuth } from 'contexts';
import logo from 'logos/RainbookFullLogoWhite.png';
import { AdvisorAnalyzerLinks, NavItem, WealthHubLinks } from 'utils/navItems';

interface StyleProps {
  onHub: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: (props) => ({
    width: '100%',
    minHeight: props.onHub ? '100vh' : 'auto',
    paddingTop: props.onHub ? '4rem' : '3rem',
    backgroundColor: props.onHub ? 'rgb(200, 223, 241)' : 'white'
  }),
  nav: {
    position: 'fixed',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '3.5rem',
    background: (props) =>
      props.onHub ? 'rgb(21, 120, 246)' : 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))',
    zIndex: 10
  },
  leftNav: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: '1rem'
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '8rem',
    height: '100%',
    filter: 'grayscale(100%) brightness(1000%)',
    cursor: 'pointer'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    padding: '0 1rem',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    transition: 'all 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  },
  dropdownBtn: {
    width: '2rem',
    height: '2rem',
    cursor: 'pointer'
  }
}));

export const NoAuthNav = ({ children, homeTo }: { children: React.ReactNode, homeTo: string }) => {

  const onHub = false;

  const classes = useStyles({ onHub });

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <div className={classes.leftNav}>
          <Link to={homeTo} className={classes.logoContainer}>
            <img src={logo} alt="rainbook" style={{ width: '5rem' }} />
          </Link>
          <div className={classes.links}>
          </div>
        </div>
        <NoAuthNavDropdown homeTo={homeTo} />
      </div>
      {children}
    </div>
  );
};
