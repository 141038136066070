import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Dialog, DialogContent } from '@material-ui/core';
import { Widget } from '@typeform/embed-react';

import { InvestorLayout } from 'components';
import { LinkStep, NewAccountSteps, ProcessingMessage } from 'components';
import SignUpIframe from 'components/auth/SignUpIframe';
import { Step } from 'components/cards/NewAccountSteps';
import DialogTitleX from 'components/dialogs/DialogTitleX';
import { useAuth, useLeadsData } from 'contexts';

import useStyles from './LandingStyles';
import { AdvisorNetworkSection, ConnectNoAdvisorSection } from './sections';

export const LandingNoAuthNoAdvisor = () => {
  const classes = useStyles();
  const [openSignup, setOpenSignup] = useState(false);
  const [openedTypeform, setOpenedTypeform] = useState(true);
  const { leadId } = useAuth();
  const {
    investorUserData: { lead, provided_location_preference, provided_account_size_max },
    scoreSubmitted,
    setScoreSubmitted
  } = useLeadsData();

  const noAdvisorPending = scoreSubmitted.includes('provided_location_preference');

  let noAdvisorSteps: [Step] = [
    {
      title: '',
      isComplete: provided_location_preference,
      actionText: 'Find Your Perfect Advisor - Start Now',
      action: 'MWzDkBhJ',
      score_attribute: 'provided_location_preference'
    }
  ];

  const closeSignup = () => {
    setOpenSignup(false);
  };

  const closeTypeform = () => {
    setOpenedTypeform(false);
  };

  const handleNoAdvisorTypeformSubmit = () => {
    setScoreSubmitted((arr: any) => [...arr, 'provided_location_preference']);
  };
  const noAdvisorTypeformPane = (
    <Dialog fullScreen open={openedTypeform} fullWidth={true} maxWidth={'lg'} onClose={closeTypeform}>
      <DialogTitleX onClose={closeTypeform} style={{ backgroundColor: 'rgb(0, 65, 114)' }} />
      <DialogContent style={{ backgroundColor: 'rgb(0, 65, 114)' }}>
        <Widget
          id="MWzDkBhJ"
          onSubmit={handleNoAdvisorTypeformSubmit}
          style={{ width: '100%', height: '100%' }}
          hidden={{
            lead_client_id: leadId,
            email: lead?.email || ''
          }}
        />
      </DialogContent>
    </Dialog>
  );

  return (
    <InvestorLayout>
      {provided_account_size_max ? (
        <div className={classes.pageHeader}>
          <h2>{lead?.first_name}, you are now matched with two fiduciary advisors!</h2>
          <Link className={classes.button} to="/a/advisor-find/match">
            Discover Your Matches
          </Link>
        </div>
      ) : (
        <h2 className={classes.pageHeader}>
          Out of 700,000 Financial Advisors, Only 12% Are True Fiduciaries. This is Rainbook.
        </h2>
      )}
      <div className={classes.section}>
        <AdvisorNetworkSection />
        {noAdvisorPending ? (
          <div className={classes.row}>
            <ProcessingMessage />
          </div>
        ) : provided_location_preference ? (
          <>
            <div className={classes.row}>
              <p>
                Based on your information provided, we have matched you with <b>two premier fiduciary advisors</b> on
                the Rainbook Advisor Network for a <i>free</i> consultation. Rainbook members make the first move.
                Strict no spam policy.
              </p>
            </div>
            <div className={classes.match}>
              <LinkStep
                title=""
                actionText="Schedule a free, no obligation consultation - Discover Your Matches"
                to="/a/advisor-find/match"
              />
            </div>
          </>
        ) : (
          <div className={classes.row}>
            {noAdvisorTypeformPane}
            <NewAccountSteps steps={noAdvisorSteps} />
          </div>
        )}
      </div>
      <div className={classes.section}>
        <ConnectNoAdvisorSection isConnectComplete={false} />
        <div className={classes.row}>
          <div className={classes.match}>
            <div className={classes.button} onClick={() => setOpenSignup(true)}>
              Securely Register to Connect Accounts
            </div>
          </div>
          <Dialog open={openSignup} onClose={closeSignup} aria-labelledby="form-dialog-title">
            <SignUpIframe />
          </Dialog>
        </div>
      </div>
    </InvestorLayout>
  );
};
