import React, { useState } from 'react';

import useStyles from '../LandingStyles';

export const FeesSection = () => {
  const [expandedFee, setExpandedFee] = useState('aum');
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div>Fees you might be paying:</div>
      <div className={classes.tabbedArea}>
        <div className={classes.tabs}>
          <div className={(expandedFee === 'aum' && classes.selectedTab) || ''} onClick={() => setExpandedFee('aum')}>
            <b>Management Fee</b>
            <div>Should be 1% or less</div>
          </div>
          <div className={(expandedFee === 'fund' && classes.selectedTab) || ''} onClick={() => setExpandedFee('fund')}>
            <b>Fund Fees</b>
            <div>0.36% average</div>
          </div>
          <div className={(expandedFee === 'load' && classes.selectedTab) || ''} onClick={() => setExpandedFee('load')}>
            <b>Commission Fees</b>
            <div>None for a fiduciary</div>
          </div>
        </div>
        <div className={classes.tabbedBody}>
          {expandedFee === 'aum' ? (
            <ul className={classes.standardList}>
              <li>Calculated based on a percentage of your assets under management, charged quarterly or monthly.</li>
              <li>Averages around 0.80% - 1.0%</li>
            </ul>
          ) : expandedFee === 'fund' ? (
            <ul className={classes.standardList}>
              <li>Fees associated with the mutual funds or ETFs held in your account, charged indirectly.</li>
              <li>Sometimes called layered fees or expense ratio fees.</li>
              <li>Average fee paid for passive funds is 0.11% and across investor portfolios is 0.36%.</li>
            </ul>
          ) : expandedFee === 'load' ? (
            <ul className={classes.standardList}>
              <li>Commission or sales fees charged on top of the annual fund fee.</li>
              <li>
                Including a 12b-1 fee which is used as a commission to a <b>broker</b> to sell you the fund.
              </li>
              <li>Front Load fees are charged up front when you buy certain mutual funds.</li>
              <li>
                Note: Fiduciary Advisors do not charge load fees as they represent a substantial conflict of interest.
                Average fee range is an eye popping 3.75% - 5.75%.
              </li>
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        To see how <i>your</i> fees compare, connect to analyze your advisor managed account:
      </div>
    </div>
  );
};
