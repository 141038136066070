import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { FixConnectionButton, LinkStep, MorningstarConnect, NewAccountSteps, ProcessingMessage } from 'components';
import { Step } from 'components/cards/NewAccountSteps';
import { useAccountsData, useAuth } from 'contexts';

import useStyles from './LandingStyles';
import { AdvisorNetworkSection, ConnectNoAdvisorSection } from './sections';

export const LandingNoAdvisor = () => {
  const classes = useStyles();
  const history = useHistory();
  const mstarRef = useRef<HTMLInputElement>(null);

  const {
    baaUserData: { baa_investor_credentials },
    investorUserData: {
      advisor,
      issue_types,
      score_cash,
      score_fees,
      statement_in_progress,
      provided_location_preference
    },
    BaaAccounts,
    scoreSubmitted
  } = useAccountsData();

  const { noAdvisor, currentUser } = useAuth();

  const issueTypes: [string] = issue_types || [];
  const isDataIssue = issueTypes.includes('data');
  const performancePending = scoreSubmitted.includes('score_performance') && (noAdvisor || advisor);
  const noAdvisorPending = scoreSubmitted.includes('provided_location_preference');
  const isConnectComplete = !!(score_fees && score_cash);

  const connectStep = baa_investor_credentials?.length
    ? {
        title: 'Connect Account or Upload Statements',
        isComplete: isConnectComplete,
        actionText: 'Fix Connection Issues',
        score_attribute: 'score_fees',
        action: () => mstarRef.current?.click()
      }
    : {
        title: 'Connect Account or Upload Statements',
        isComplete: isConnectComplete,
        isPending: BaaAccounts.length > 0,
        actionText: 'Connect or Upload',
        score_attribute: 'score_fees',
        action: () => history.push('/advisor-analyzer/add-account')
      };

  let noAdvisorSteps: [Step] = [
    {
      title: 'Find your Advisor Match',
      isComplete: provided_location_preference,
      actionText: 'Answer a few Questions',
      action: 'KVKEduIx',
      score_attribute: 'provided_location_preference'
    }
  ];

  const firstName = currentUser?.displayName ? currentUser.displayName.split(' ')[0] : undefined;

  return (
    <>
      <h2 className={classes.title}>Welcome to Rainbook{firstName ? `, ${firstName}` : ''}!</h2>
      <div className={classes.section}>
        <AdvisorNetworkSection />
        {noAdvisorPending ? (
          <div className={classes.row}>
            <ProcessingMessage />
          </div>
        ) : provided_location_preference ? (
          <>
            <div className={classes.row}>
              <p>
                Based on your information provided, we have matched you with <b>two premier fiduciary advisors</b> on
                the Rainbook Advisor Network for a <i>free</i> consultation. Rainbook members make the first move.
                Strict no spam policy.
              </p>
            </div>
            <div className={classes.match}>
              <LinkStep
                title=""
                actionText="Schedule a free, no obligation consultation - Discover Your Matches"
                to="/advisor-analyzer/advisor-match"
              />
            </div>
          </>
        ) : (
          <div className={classes.row}>
            <NewAccountSteps steps={noAdvisorSteps} />
          </div>
        )}
      </div>
      <div className={classes.section}>
        <ConnectNoAdvisorSection isConnectComplete={isConnectComplete} />
        {statement_in_progress || isDataIssue || performancePending ? (
          <div className={classes.row}>
            <ProcessingMessage {...{ statement_in_progress, isDataIssue }} />
          </div>
        ) : (
          <div className={classes.row}>
            <NewAccountSteps steps={[connectStep]} />
          </div>
        )}
        <FixConnectionButton show={!!baa_investor_credentials?.length} mstarRef={mstarRef} />
      </div>
      <MorningstarConnect refForClick={mstarRef} />
    </>
  );
};
