import React from 'react';

import { CollapsibleText } from 'components';

import useStyles from '../LandingStyles';

export const AdvisorRedFlagsSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <CollapsibleText
        title={
          <div className={classes.collapsibleTitle}>
            <b>Conflict of interest?</b>
          </div>
        }
        text={
          <ul>
            <li>88% of financial advisers are registered brokers and have conflicts.</li>
            <li>
              Only independent investment advisors are legally bound to the fiduciary standard ALL the time. They are
              not allowed to receive commissions.
            </li>
          </ul>
        }
      />
      <CollapsibleText
        title={
          <div className={classes.collapsibleTitle}>
            <b>Lacking Credentials?</b>
          </div>
        }
        text={
          <ul>
            <li>Look for the CFP®, CFA or CPA.</li>
            <li>Certified Financial Planners focus on long term holistic financial planning.</li>
          </ul>
        }
      />
      <CollapsibleText
        title={
          <div className={classes.collapsibleTitle}>
            <b>Disclosures or Complaints?</b>
          </div>
        }
        text={
          <ul>
            <li>Disclosures on an advisor's record can be indicative of a problematic history.</li>
          </ul>
        }
      />
      <div>
        Answer a few questions to find out if <i>your</i> advisor is a fiduciary and if they have any red flags.
      </div>
    </div>
  );
};
