import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import { useLeadsData } from 'contexts';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

import DialogTitleX from '../dialogs/DialogTitleX';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const url = process.env.REACT_APP_API_URL;

const STATE_OPTIONS = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
];

export const AdvisorNamePopup = ({ open, onClose }: DialogProps) => {
  const { currentUser } = useAuth();
  const userData = useAccountsData();
  const leadData = useLeadsData();
  const { investorUserData, getAAData, updateInvestor } = userData || leadData;

  const [advisorFirstName, setAdvisorFirstName] = useState('');
  const [advisorLastName, setAdvisorLastName] = useState('');
  const [advisorState, setAdvisorState] = useState(investorUserData.provided_advisor_state);
  const [errorMsg, setErrorMsg] = useState(
    investorUserData.provided_advisor_name ? `We could not find ${investorUserData.provided_advisor_name}` : undefined
  );
  const [loading, setLoading] = useState(false);

  const handleAdvisorFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvisorFirstName(event.target.value);
  };
  const handleAdvisorLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvisorLastName(event.target.value);
  };

  //const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const submitAdvisorName = async () => {
    const resp = await updateInvestor(
      investorUserData.id,
      {
        provided_advisor_name: `${advisorFirstName} ${advisorLastName}`,
        provided_advisor_state: advisorState
      },
      false
    );
    setLoading(false);
    if (!resp.ok) {
      try {
        const json = await resp.json();
        setErrorMsg(json['errors'][0]['detail']);
      } catch (e) {
        setErrorMsg('Error finding Advisor, please contact support.');
      }
    } else {
      getAAData();
      onClose();
    }
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    setErrorMsg('');
    setLoading(true);
    submitAdvisorName();
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitleX onClose={onClose}>Advisor Name</DialogTitleX>
        <DialogContent>
          <p style={{ color: 'red' }}>{errorMsg}</p>
          <form onSubmit={handleSubmit}>
            <div style={{ padding: '10px 0px' }}>Enter your Advisor's First and Last name</div>
            <div>
              <TextField
                autoFocus
                margin="normal"
                variant="outlined"
                id="name"
                label="First name"
                type="text"
                onChange={handleAdvisorFirstNameChange}
                fullWidth
              />
            </div>
            <div>
              <TextField
                margin="normal"
                variant="outlined"
                id="name"
                label="Last name"
                type="text"
                onChange={handleAdvisorLastNameChange}
                fullWidth
              />
            </div>
            <div>
              <InputLabel style={{ padding: '10px 0px' }} id="advisor-state-label">
                Advisor's State
              </InputLabel>
              <Select variant="outlined" name="advisor-state" label="advisor-state-label" value={advisorState}>
                {STATE_OPTIONS.map((item, i) => (
                  <MenuItem key={i} onClick={() => setAdvisorState(item[1])} value={item[1]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <DialogActions className={'phone-dialog-verify'}>
              <Button disabled={loading} type="submit" color="primary" variant="contained">
                {loading ? <CircularProgress size={20} /> : 'Submit'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
