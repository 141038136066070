import React, { useEffect, useMemo, useRef, useState } from 'react';

import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { FixConnectionButton, TooltipIcon } from 'components';
import { useAccountsData } from 'contexts';

interface CircularMeterProps {
  value: number;
  change: number;
  changeInverted?: boolean;
  size?: number;
  strokeWidth?: number;
  display?: string | number;
  noScore?: any;
  footer?: string;
  useFixedPosition?: boolean;
  mstarRef?: any;
}

type Rating = 'Poor' | 'Fair' | 'Good' | '';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-evenly'
  },
  meterHeader: {
    flex: 1,
    fontSize: '1.5rem',
    fontWeight: 900,
    textAlign: 'center',
    paddingBottom: '10px'
  },
  circularMeter: {
    height: '220px',
    flexDirection: 'column'
  },
  circularMeterSvg: {
    transform: 'rotate(-90deg)'
  },
  circularMeterBackground: {
    stroke: '#aaa',
    strokeLinecap: 'round'
  },
  circularMeterCircle: (props: { rating: Rating }) => ({
    stroke: '#eee',
    strokeLinecap: 'round'
  }),
  circularMeterValueDot: (props: { rating: Rating }) => ({
    stroke: '#eee',
    fill: '#eee'
  }),
  circularMeterValue: {
    position: 'absolute',
    marginTop: '5rem',
    width: '240px',
    textAlign: 'center',
    fontSize: '3rem',
    fontWeight: 900
  },
  circularMeterLabel: {
    position: 'absolute',
    marginTop: '9rem',
    width: '240px',
    textAlign: 'center',
    fontSize: '2rem',
    fontStyle: 'italic',
    fontWeight: 700
  },
  footerText: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
    fontSize: '0.8rem',
    textAlign: 'center'
  },
  advisorText: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: '0.8rem 1.5rem',
    color: 'rgb(21, 120, 246)',
    fontWeight: 700,
    border: '1px solid rgb(21, 120, 246)',
    borderRadius: '0.5rem',
    margin: '1rem',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      borderColor: 'white',
      backgroundColor: 'rgb(21, 120, 246)'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none'
    }
  }
});

export const ScoreMeterSimple: React.FC<CircularMeterProps> = ({
  value,
  display,
  size = 220,
  strokeWidth = 10,
  noScore,
  footer,
  useFixedPosition,
  mstarRef
}) => {
  const rating: Rating = useMemo(() => {
    if (value < 80) {
      return 'Poor';
    } else if (value < 90) {
      return 'Fair';
    } else if (value <= 100) {
      return 'Good';
    } else {
      return '';
    }
  }, [value]);

  const classes = useStyles({ rating });
  const { baaUserData, investorUserData } = useAccountsData();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const meterRef = useRef<SVGCircleElement>(null);
  const [meterLength, setMeterLength] = useState(0);
  useEffect(() => {
    if (meterRef.current) {
      setMeterLength(meterRef.current.getTotalLength());
    }
  }, [meterRef]);

  const padding = 10;
  const staticPercent = 0.665;
  const staticOffset = meterLength * (1 - staticPercent);

  const { dotX, dotY, offset } = useMemo(() => {
    const fixedValue = rating === 'Poor' ? 25 : rating === 'Fair' ? 50 : rating === 'Good' ? 90 : 0;
    const usedValue = useFixedPosition ? fixedValue : value && value > 49 ? ((value - 50) / 50) * 100 : 0;
    const scaledValue = usedValue ? usedValue * staticPercent : 0;
    const percent = scaledValue / 100;
    const offset = meterLength * (1 - percent);
    const angle = (scaledValue * 350) / 100 - 116;
    const dotX = size / 2 + (size / 2 - strokeWidth / 2) * Math.cos((angle * Math.PI) / 180);
    const dotY = size / 2 + (size / 2 - strokeWidth / 2) * Math.sin((angle * Math.PI) / 180);
    return { dotX, dotY, offset };
  }, [rating, useFixedPosition, value, meterLength, size, strokeWidth]);

  return (
    <div className={classes.container}>
      <div className={classes.meterHeader}>
        Rainbook Advisor Score{' '}
        <TooltipIcon title="Your RAIN® report advisor score is determined through the consideration of weighted factors, encompassing elements such as your fees, relative performance, investment offering, advisor independence, credentials, and the level of engagement exhibited by your advisor in the client relationship." />
      </div>
      {(!isSm || !!value) && (
        <div className={classes.circularMeter}>
          {value ? (
            <>
              <div className={classes.circularMeterValue}>{display || rating}</div>
              <div className={classes.circularMeterLabel}>{display ? rating : ''}</div>
            </>
          ) : (
            <div className={classes.circularMeterValue}>---</div>
          )}
          <svg className={classes.circularMeterSvg} width={size + padding * 2} height={size + padding * 2}>
            <circle
              className={classes.circularMeterBackground}
              r={size / 2 - strokeWidth / 2}
              cx={size / 2 + padding}
              cy={size / 2 + padding}
              strokeWidth={strokeWidth}
              strokeDasharray={meterLength}
              strokeDashoffset={staticOffset}
              fill="none"
              transform={`rotate(-120 ${size / 2 + padding} ${size / 2 + padding})`}
            />
            <circle
              ref={meterRef}
              className={classes.circularMeterCircle}
              r={size / 2 - strokeWidth / 2}
              cx={size / 2 + padding}
              cy={size / 2 + padding}
              strokeWidth={strokeWidth}
              fill="none"
              strokeDasharray={meterLength}
              strokeDashoffset={offset}
              transform={`rotate(-120 ${size / 2 + padding} ${size / 2 + padding})`}
            />
            <circle
              className={classes.circularMeterValueDot}
              r="10"
              cx={dotX + padding}
              cy={dotY + padding}
              strokeWidth={8}
            />
          </svg>
        </div>
      )}
      <div className={classes.footerText}>
        {value ? (
          <>
            {!!investorUserData?.advisor && (
              <div className={classes.advisorText}>
                Advisor:{' '}
                {investorUserData.provided_advisor_name ||
                  `${investorUserData.advisor.last_name} ${investorUserData.advisor.last_name}`}
              </div>
            )}
            {footer && <div>{footer}</div>}
            <div>Updated Daily</div>
          </>
        ) : (
          !isSm && <>{noScore}</>
        )}
      </div>
      <FixConnectionButton show={!!baaUserData?.baa_investor_credentials?.length} mstarRef={mstarRef} />
    </div>
  );
};
