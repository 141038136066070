import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useInterval } from 'utils';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useAuth } from './AuthContext';

const LeadsContext = React.createContext();

const url = process.env.REACT_APP_API_URL;

export function useLeadsData() {
  return useContext(LeadsContext);
}

const getInvestorData = async () => {
  const userResponse = await fetch(`${url}/api/v1/investors/current.json?include=advisor,lead`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (userResponse.ok) {
    return (await userResponse.json()).data;
  } else {
    return undefined;
  }
};

export function LeadsProvider({ children }) {
  const [investorUserData, setInvestorUserData] = useState({});
  const [currentLead, setCurrentLead] = useState();
  const [scoreSubmitted, setScoreSubmitted] = useState([]);
  const [checkInterval, setCheckInterval] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  const { leadId } = useAuth();

  const getAAData = useCallback(
    async () => {
      const investorData = await getInvestorData();
      setInvestorUserData(investorData || {});
      setCurrentLead(investorData?.lead);
      return investorData;
    },
    []
  );

  const updateInvestor = useCallback(
    async (investorId, attributes, updateData) => {
      const res = await fetch(`${url}/api/v1/investors/${investorId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          data: {
            type: 'investors',
            id: investorId,
            attributes
          }
        })
      }).catch((err) => {
        console.error('Failed to update investor: ', err);
        return;
      });
      if (updateData) {
        getAAData();
      }
      return res;
    },
    [getAAData]
  );

  const createMeeting = useCallback(
    async (attributes) => {
      const res = await fetch(`${url}/api/v1/meetings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          data: {
            type: 'meetings',
            attributes
          }
        })
      }).catch((err) => {
        console.error('Failed to create meeting: ', err);
        return;
      });
      getAAData();
      return res;
    },
    [getAAData]
  );

  const checkForScore = async () => {
    if (scoreSubmitted.length > 0) {
      const data = await getInvestorData();
      let found = false;
      for (let i = scoreSubmitted.length - 1; i >= 0; i--) {
        if (data && data[scoreSubmitted[i]] && data[scoreSubmitted[i]] !== 0) {
          found = true;
          scoreSubmitted.splice(i, 1);
        }
      }
      if (found) {
        getAAData();
      }
    }
    if (scoreSubmitted.length === 0) {
      setCheckInterval(null);
    }
  };
  useEffect(() => {
    if (scoreSubmitted.length > 0) {
      setCheckInterval(5000);
    }
  }, [scoreSubmitted, setCheckInterval]);
  useInterval(checkForScore, checkInterval);

  useEffect(() => {
    async function getData() {
      await getAAData();
      setInitialLoading(false);
    }
    if (leadId) {
      getData(); //not waiting, sets loading when done
    }
  }, [leadId]);

  const value = {
    investorUserData,
    scoreSubmitted,
    setScoreSubmitted,
    getAAData,
    updateInvestor,
    createMeeting,
    currentLead,
  };

  return (
    <LeadsContext.Provider value={value}>
      {(!initialLoading || !leadId) && children}
      <Backdrop open={initialLoading} styles={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </LeadsContext.Provider>
  );
}
