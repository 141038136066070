import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { LocationChange, MainNav, NoAuthNav } from 'components';
import PersonalFinancialStatement from 'components/assetDetails/PersonalFinancialStatement';
import LogIn from 'components/auth/LogIn';
import PrivateRoute from 'components/auth/PrivateRoute';
import SignUp from 'components/auth/SignUp';
import SignUpIframe from 'components/auth/SignUpIframe';
import { AccountsProvider } from 'contexts/AccountsContext';
import { AuthProvider } from 'contexts/AuthContext';
import { LeadsProvider } from 'contexts/LeadsContext';
import { NetWorthProvider } from 'contexts/NetWorthDataContext';
import Profile from 'pages/Profile';
import Vault from 'pages/Vault';
import {
  AddAdvisorAccountWrapper,
  AdvisorAnalyzer,
  AdvisorMatch,
  InvestorStart,
  Landing,
  LandingNoAuthAdvisor,
  LandingNoAuthNoAdvisor
} from 'pages/financialAdvisor';
import {
  AlternativesFactor,
  CredentialsFactor,
  EngagementFactor,
  FeesFactor,
  PerformanceFactor,
  StatementsFactor
} from 'pages/financialAdvisor/factors';
import Alternative from 'pages/wealthHub/Alternative';
import Dashboard from 'pages/wealthHub/Dashboard';
import ExpenseTracker from 'pages/wealthHub/ExpenseTracker';
import InsightView from 'pages/wealthHub/InsightView';
import Insights from 'pages/wealthHub/Insights';
import Liabilities from 'pages/wealthHub/Liabilities';
import Liquid from 'pages/wealthHub/Liquid';
import Personal from 'pages/wealthHub/Personal';
import ScrollToTop from 'utils/scrollToTop';

import './App.css';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string);
  }, []);

  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '0.9em',
          padding: '1rem',
          color: 'black',
          backgroundColor: '#f8f8f8'
        }
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/signup-iframe" component={SignUpIframe} />
            <Route exact path="/login" component={LogIn} />
            <Route path="/a/">
              <LeadsProvider>
                <Route path="/a/advisor-find">
                  <NoAuthNav homeTo="/a/advisor-find">
                    <Switch>
                      <Route exact path="/a/advisor-find" component={LandingNoAuthNoAdvisor} />
                      <Route exact path="/a/advisor-find/match">
                        <AdvisorMatch />
                      </Route>
                    </Switch>
                  </NoAuthNav>
                </Route>
                <Route path="/a/advisor-upgrade">
                  <NoAuthNav homeTo="/a/advisor-upgrade">
                    <Switch>
                      <Route exact path="/a/advisor-upgrade" component={LandingNoAuthAdvisor} />
                      <Route exact path="/a/advisor-upgrade/match">
                        <AdvisorMatch />
                      </Route>
                    </Switch>
                  </NoAuthNav>
                </Route>
              </LeadsProvider>
            </Route>
            <AccountsProvider>
              <LocationChange />
              <NetWorthProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <PrivateRoute exact path="/start" component={InvestorStart} />
                  <MainNav>
                    <PrivateRoute exact path="/" component={Landing} />
                    <PrivateRoute exact path="/advisor-analyzer" component={AdvisorAnalyzer} />
                    <PrivateRoute exact path="/advisor-analyzer/performance-factor" component={PerformanceFactor} />
                    <PrivateRoute exact path="/advisor-analyzer/fees-factor" component={FeesFactor} />
                    <PrivateRoute exact path="/advisor-analyzer/alternatives-factor" component={AlternativesFactor} />
                    <PrivateRoute exact path="/advisor-analyzer/statements-factor" component={StatementsFactor} />
                    <PrivateRoute exact path="/advisor-analyzer/engagement-factor" component={EngagementFactor} />
                    <PrivateRoute exact path="/advisor-analyzer/credentials-factor" component={CredentialsFactor} />
                    <PrivateRoute exact path="/advisor-analyzer/advisor-match" component={AdvisorMatch} />
                    <PrivateRoute
                      exact
                      path="/advisor-analyzer/advisor-match/:advisor_profile_id"
                      component={AdvisorMatch}
                    />
                    <PrivateRoute exact path="/advisor-analyzer/add-account" component={AddAdvisorAccountWrapper} />
                    <PrivateRoute exact path="/wealth-hub" component={Dashboard} />
                    <PrivateRoute exact path="/wealth-hub/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/wealth-hub/liquid" component={Liquid} />
                    <PrivateRoute exact path="/wealth-hub/alternative" component={Alternative} />
                    <PrivateRoute exact path="/wealth-hub/personal" component={Personal} />
                    <PrivateRoute exact path="/wealth-hub/expense-tracker" component={ExpenseTracker} />
                    <PrivateRoute exact path="/wealth-hub/liabilities" component={Liabilities} />
                    <PrivateRoute exact path="/wealth-hub/insights" component={Insights} />
                    <PrivateRoute path="/wealth-hub/insights/:permalink" component={InsightView} />
                    <PrivateRoute exact path="/wealth-hub/vault" component={Vault} />
                    <PrivateRoute exact path="/wealth-hub/pfs" component={PersonalFinancialStatement} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                  </MainNav>
                </MuiPickersUtilsProvider>
              </NetWorthProvider>
            </AccountsProvider>
          </Switch>
        </Router>
      </AuthProvider>
    </MuiThemeProvider>
  );
}

export default App;
