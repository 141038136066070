import React from 'react';
import { FaSearch } from 'react-icons/fa';

import useStyles from '../LandingStyles';

export const ConnectNoAdvisorSection = ({ isConnectComplete }: { isConnectComplete: boolean }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <h2 className={classes.title}>
          <FaSearch style={{ verticalAlign: 'middle' }} /> Get advice on your current portfolio.
        </h2>
      </div>
      <div className={classes.row}>
        {!isConnectComplete ? (
          <ul className={classes.list}>
            <li>Rainbook will analyze your portfolio to streamline your discussion with a fiduciary advisor.</li>
            <li>
              By connecting your investment account we can deliver a snapshot of your current financial situation:
            </li>
          </ul>
        ) : (
          <p>Thank you for connecting an account. This will streamline your discussion with a fiduciary advisor.</p>
        )}
      </div>
    </>
  );
};
