import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FaFooter } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fdfdfd',
    overflow: 'hidden',
    paddingTop: '1rem',
  }
}));

export const InvestorLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children}
      <FaFooter />
    </div>
  )
}
