import React, { useRef, useState } from 'react';

import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

import VerifyEmailPopup from 'components/popups/VerifyEmailPopup';
import { useAuth } from 'contexts/AuthContext';
import { useLeadsData } from 'contexts/LeadsContext';
import validate from 'utils/ValidateSignUp';

import SsoButtons from './SsoButtons';

import './SignUp.css';
import 'components/popups/VerifyEmailPopup.css';

const env =
  process.env.NODE_ENV === 'development' ? 'local.' : process.env.REACT_APP_ENV === 'development' ? 'dev.' : '';
const appUrl = `https://${env}app.rainbook.com`;

const useStyles = makeStyles((theme) => ({
  signUpFormContentiFrame: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: 'rgba(120, 121, 125, 0.3) 0px 2px 8px 0px',
    borderRadius: '6px',
    padding: '16px',
    width: '100%',
    minWidth: '0px',
    margin: '0px'
  }
}));

const SignUpIframe = () => {
  const currentLead = useLeadsData()?.currentLead;
  const [values, setValues] = useState({ email: currentLead?.email, firstName: currentLead?.first_name, lastName: currentLead?.last_name });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const agreeRef = useRef();
  const { signUp, sendVerificationEmail, updateNewUserAttributes, leadId } = useAuth();
  const classes = useStyles();

  async function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const userData = await signUp(emailRef.current.value, passwordRef.current.value);
        if (userData?.user) {
          const { user } = userData;
          await user.updateProfile({
            displayName: wholeName,
            photoURL: ''
          });
          updateNewUserAttributes(user, { lead_client_id: leadId })
          await sendVerificationEmail(user);
          setSendEmail(true);
        }
      } catch (e) {
        setErrors({ password: e.message });
      } finally {
        setLoading(false);
      }
    }
  }

  const wholeName = values.firstName + ' ' + values.lastName;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let v = value;
    if (name === 'agree') v = checked;
    setValues({
      ...values,
      [name]: v
    });
  };

  const okEmailClick = () => {
    window.top.location.href = appUrl;
  };

  return (
    <div>
      {sendEmail ? <VerifyEmailPopup newAccount={true} okClick={okEmailClick} /> : null}
      <Grid container>
        <Grid item xs={12} className="sign-up-left">
          <div className={classes.signUpFormContentiFrame}>
            <form className="sign-up-form" onSubmit={handleSubmit} noValidate>
              <h2 className="sign-up-sub-header">
                Register to Securely Connect Account.
              </h2>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="text"
                    name="firstName"
                    className="sign-up-form-input-box"
                    value={values.firstName || ''}
                    ref={firstNameRef}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  {errors.firstName && <p className="sign-up-error-msg">{errors.firstName}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="text"
                    name="lastName"
                    className="sign-up-form-input-box"
                    value={values.lastName || ''}
                    ref={lastNameRef}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  {errors.lastName && <p className="sign-up-error-msg">{errors.lastName}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="email"
                    name="email"
                    className="sign-up-form-input-box"
                    value={values.email || ''}
                    ref={emailRef}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  {errors.email && <p className="sign-up-error-msg">{errors.email}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="password"
                    name="password"
                    className="sign-up-form-input-box"
                    value={values.password || ''}
                    ref={passwordRef}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  {errors.password && <p className="sign-up-error-msg">{errors.password}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div className="log-in-msg-text">
                  {errors.agree && (
                    <span style={{ display: 'inline-block' }} className="sign-up-error-msg">
                      {' '}
                      {errors.agree}
                    </span>
                  )}
                  <input
                    type="checkbox"
                    name="agree"
                    style={{ marginRight: 5 }}
                    value={values.agree}
                    ref={agreeRef}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <span>I agree to the </span>
                  <a href="https://www.rainbook.com/terms-of-use" target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                  <span> and </span>
                  <a href="https://www.rainbook.com/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
              <button
                disabled={loading}
                className="sign-up-form-input-btn"
                style={{ opacity: loading ? 0.5 : 1 }}
                type="submit"
              >
                {loading ? <CircularProgress color="primary" size={25} /> : 'Create Account'}
              </button>
              <div className="sso-area">
                <div style={{ padding: '0px 0px 10px 0px' }}>-or-</div>
                {errors.sso && (
                  <span style={{ display: 'inline-block' }} className="sign-up-error-msg">
                    {' '}
                    {errors.sso}
                  </span>
                )}
                <SsoButtons setErrors={setErrors} isIframe isSignUp />
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpIframe;
