import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { makeStyles } from '@material-ui/core';

interface Props {
  show: boolean;
  mstarRef?: any;
}

const useStyles = makeStyles({
  linkButton: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
    backgroundColor: 'rgb(249,196,0)',
    padding: '0.8rem 1.5rem',
    color: '#333',
    fontWeight: 700,
    border: '1px solid rgb(21, 120, 246)',
    borderRadius: '0.5rem',
    margin: '1rem',
    maxWidth: '300px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      textDecoration: 'none',
      borderColor: 'white'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none'
    }
  }
});

export const FixConnectionButton: React.FC<Props> = ({ show, mstarRef }) => {
  const classes = useStyles();

  const fixConnection = () => {
    mstarRef.current?.click();
  };

  return !!mstarRef && show ? (
    <button className={classes.linkButton} onClick={fixConnection}>
      Review Connection Issues <AiOutlineArrowRight />
    </button>
  ) : (
    <></>
  );
};
