import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageHeader: {
    padding: '0 1em',
    textAlign: 'center',
    maxWidth: '900px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2em',
      maxWidth: '720px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
      '& > a': {
        fontSize: 'smaller'
      }
    }
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    paddingBottom: '2rem',
    width: '750px',
    margin: '2rem',
    border: '3px solid #eee',
    borderRadius: '9px',
    [theme.breakpoints.down('sm')]: {
      width: '500px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      border: 'none',
      margin: '0.5rem 0',
      padding: '0'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  list: {
    marginTop: '0',
    marginBottom: '0',
    '& > li': {
      paddingBottom: '10px',
      margin: '8px 0',
      '& li': {
        margin: '6px 0'
      }
    }
  },
  standardList: {
    paddingInlineStart: '2em',
    '& li': {
      margin: '6px 0'
    }
  },
  rowRow: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  rowAlert: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'red',
    color: 'white',
    borderRadius: '4px',
    padding: '1rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      '& svg': {
        display: 'none'
      }
    }
  },
  title: {
    textAlign: 'left',
    display: 'inline',
    '& span': {
      fontWeight: 'normal'
    },
    '& svg': {
      verticalAlign: 'middle',
      marginRight: '5px',
      fontSize: '1.2em'
    }
  },
  tabbedArea: {
    display: 'flex',
    flexDirection: 'column'
  },
  tabbedBody: {
    backgroundColor: '#eee',
    borderRadius: '9px',
    padding: '2em'
  },
  tabs: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'center',
    '&> div': {
      cursor: 'pointer',
      '& div': {
        paddingTop: '1em',
        fontSize: 'smaller'
      },
      backgroundColor: '#fff',
      padding: '20px 0 20px 0',
      width: '200px',
      border: 'solid 1px #eee',
      borderBottom: 'none',
      borderRadius: '9px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      [theme.breakpoints.down('xs')]: {
        width: '125px'
      }
    }
  },
  selectedTab: {
    backgroundColor: '#eee !important'
  },
  trademark: {
    fontSize: 'small'
  },
  collapsibleTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  collapsibleTitleText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  match: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    alignItems: 'center',
    justifyContent: 'space-around',
    '& b': {
      fontSize: '1.2em'
    }
  },
  matchLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    padding: '0.8rem 1rem',
    borderStyle: 'solid',
    backgroundColor: 'rgb(21, 120, 246)',
    transition: 'background-color 0.2s ease-in-out',
    '& svg': {
      marginLeft: '5px'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)'
    }
  },
  button: {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '2rem',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    padding: '0.5rem 1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 0.75rem'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)',
      transition: 'all 0.5s ease'
    }
  }
}));
