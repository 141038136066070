import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';

import { CollapsibleText } from 'components';

import useStyles from '../LandingStyles';

export const AdvisorNetworkSection = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <h2 className={classes.title}>
          <FaHandshake /> Connect with an Advisor on the Rainbook Network
        </h2>
      </div>
      <p>
        Rainbook partners with premier wealth management firms managing over $22 billion in assets, offering holistic
        financial planning by CFP®, CFA, and CPA-designated advisors with impeccable credentials. We work exclusively
        with independent fiduciary RIAs.
      </p>
      <div className={classes.row}>
        <CollapsibleText
          title={
            <div className={classes.collapsibleTitle}>
              <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
              <b>Fiduciary</b>
              <span className={classes.collapsibleTitleText}>
                : Legally bound to act in your best interest. No brokers.
              </span>
            </div>
          }
          text={
            <>
              A fiduciary is:
              <ul>
                <li>Indpendent, always.</li>
                <li>Provides complete transparency.</li>
                <li>Rejects all commissions, avoids conflicts.</li>
              </ul>
              Whereas brokers:
              <ul>
                <li>Receive direct and indirect commissions.</li>
                <li>
                  Receive 12b-1 fee, part of those hidden fund fees you pay when owning some mutual funds or etfs.
                </li>
                <li>
                  Earn large commissions on insurance products, incentivizing them to promote and sell to clients.
                </li>
                <li>Unfortunately, 88% of investment advisors are registered brokers.</li>
              </ul>
            </>
          }
        />
      </div>
      <div className={classes.row}>
        <CollapsibleText
          title={
            <div className={classes.collapsibleTitle}>
              <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
              <b>Certified</b>
              <span className={classes.collapsibleTitleText}>: Designated CFP®, CFA or CPA.</span>
            </div>
          }
          text={
            <ul className={classes.list}>
              <li>
                Certified Financial Planner, CFP®, requires several years of delivering financial planning services in
                addition to passing the comprehensive CFP Certification Exam. The CFP® designation is the gold standard
                for financial advisors.
              </li>
              <li>
                Chartered Financial Analyst, CFA, fewer than one in five candidates become a CFA charter holder, which
                takes on average 1,000+ hours of rigorous study plus four years of professional experience.
              </li>
              <li>
                Certified Public Accountant, CPA, are tax professionals who bring a wealth of knowledge and experience
                through their continuing education requirements and relevant work experience.
              </li>
            </ul>
          }
        />
      </div>
      <div className={classes.row}>
        <CollapsibleText
          title={
            <div className={classes.collapsibleTitle}>
              <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
              <b>Fee-only</b>
              <span className={classes.collapsibleTitleText}>: Competitive pricing. No hidden fees.</span>
            </div>
          }
          text={
            <ul className={classes.list}>
              <li>
                Fee only means management fee <i>only</i>. The advisor only receives the management fee you and the
                advisor agreed to.
              </li>

              <li>
                Many other advisors are dual registered and are fee <i>based</i>. This allows them to charge commissions
                on top of their management fee.
              </li>

              <li>
                Commissions can range from selling insurance to selling certain investment products, such as mutual
                funds. Mutual funds literally pay those advisors and/or firms a 12b-1 fee for placing their investors
                money in their fund. Conflict of interest!
              </li>
            </ul>
          }
        />
      </div>
    </>
  );
};
