import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

interface Props {
  header?: string;
  title: string;
  actionText: string;
  to: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: 400,
      height: 'fit-content'
    }
  },
  title: {
    margin: 0,
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  stepsContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0.5rem',
    width: '100%',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem rgba(0,0,0,0.25)',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75rem'
    }
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    fontSize: '1.25rem',
    '& h3': {
      margin: 0
    }
  },
  mainStep: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  mainStepText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      gap: '5px'
    }
  },
  subStepList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: '0 0 0 6rem',
    margin: '0',
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  stepTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  stepActionTitle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline'
    }
  },
  subStep: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '2rem',
    gap: '1rem',
    fontStyle: 'italic',
    fontSize: '1rem'
  },
  button: {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '2rem',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    padding: '0.5rem 1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 0.75rem'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)',
      transition: 'all 0.5s ease'
    }
  },
  gray: {
    color: '#888888'
  }
}));

export const LinkStep = ({ header, title, actionText, to }: Props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        {!!header && <h1 className={classes.title}>{header}</h1>}
        <div className={classes.stepsContainer}>
          <div className={classes.step}>
            <div className={classes.mainStep}>
              <div>
                <AiFillCheckCircle size={35} color="#EEEEEE" />
              </div>
              <div className={classes.mainStepText}>
                <h3>
                  <span>
                    <span className={classes.stepTitle}>{title}</span>
                  </span>
                </h3>
                <Link className={classes.button} to={to}>
                  <span className={classes.stepActionTitle}>{title || actionText}</span>
                  <span className={classes.stepTitle}>{actionText}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
