import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  match: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
    alignItems: 'center',
    justifyContent: 'space-around',
    textDecoration: 'none',
    '& b': {
      fontSize: '1.2em'
    },
    '& h2, & h3': {
      margin: '0.2rem'
    },
    '&:visited': {
      color: 'inherit'
    }
  },
  matchButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    padding: '0.8rem 1rem',
    marginTop: '0.6rem',
    borderStyle: 'solid',
    backgroundColor: 'rgb(21, 120, 246)',
    transition: 'background-color 0.2s ease-in-out',
    '& svg': {
      marginLeft: '5px'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)'
    }
  }
}));

export const AdvisorMatchCTA = ({ linkTo }: { linkTo: string }) => {
  const classes = useStyles();

  return (
    <Link className={classes.match} to={linkTo}>
      <h2>Take control of your money.</h2>
      <h3>Click to get a free portfolio and fee review.</h3>
      <div className={classes.matchButton}>
        Schedule Now
        <AiOutlineArrowRight size={16} />
      </div>
    </Link>
  );
};
